import { routers } from "../components/Router/router.config";
import config from "../config/config";
import { USER, ADMIN, KID } from "../constants/LookupConst";
import sessionServiceInstance from "../services/session/sessionService";
import sessionService from "../services/session/sessionService";
class Utils {
    loadScript(url) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        document.body.appendChild(script);
    }

    getRoute = (path) => {
        return routers.filter((route) => route.path === path)[0];
    };

    getPageTitle = (pathname) => {
        const route = routers.filter((route) => route.path === pathname);
        return route[0].title;
    };

    getLoggedUserRedirectURL = () => {
        let dashboard;
        const user = sessionService.getLoggedUserData();

        //Login user role in the system
        let userrole = user.roles ? user.roles[0] : "";
        switch (userrole) {
            case ADMIN:
                dashboard = "/admin/dashboard";
                break;
            case USER:
                dashboard = "/app/child-dashboard";
                break;
            case KID:
                dashboard = "/app/child-dashboard";
                break;
            default:
                dashboard = "";
        }
        return dashboard;
    };

    getLoggedUserRole = () => {
        const user = sessionService.getLoggedUserData();

        //find logged in user role
        let userrole = user.roles ? user.roles[0] : "";
        return userrole;
    };

    getLoggedUserTranscriptionStatus = () => {
        const user = sessionService.getLoggedUserData();
        const hidetranscript = user.hidetranscript ? user.hidetranscript : false;
        return hidetranscript;
    };

    getExpiryDate = () => {
        var validity_days = 30;
        var expires = validity_days * 1000 * 60 * 60 * 24;
        var tokenExpireDate = new Date(new Date().getTime() + expires);
        return tokenExpireDate;
    };

    generateRandomPassword = (length) => {
        var result = "";
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    ObjectId = (m = Math, d = Date, h = 16, s = (s) => m.floor(s).toString(h)) =>
        s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

    removeProtocolFromURL = (url) => {
        if (!url) return;
        let updatedURL = url.replace(/(^\w+:|^)\/\//, "");
        let splittedURLS = updatedURL.split(".");
        if (splittedURLS && splittedURLS.length) {
            if (splittedURLS[0] === "www") {
                return splittedURLS[1];
            } else {
                return splittedURLS[0];
            }
        }
        return "error";
    };

    convertTimeToHMSFormat = (input, separator) => {
        var pad = function (input) {
            return input < 10 ? "0" + input : input;
        };
        const convertTime = [
            Math.floor(input / 3600),
            pad(Math.floor((input % 3600) / 60)),
            pad(Math.floor(input % 60)),
        ].join(typeof separator !== "undefined" ? separator : ":");

        return convertTime;
    };

    validateEmail = (email) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
}

function getOnboardingState() {
    const user = sessionServiceInstance.getLoggedUserData();
    return window.handleCheckMainOnboardingState(user);
}

export const isInReactOnboardingStep = () => getOnboardingState() === "react";
export const hasFinishedOnboarding = () => getOnboardingState() === "finished";

export function redirectAfterOnboarding() {
    window.location.replace(`${config.gameUrl}?/&"finished-react-onboarding" : true`);
}

export function toValidFilename(str) {
    // Replace invalid characters with a hyphen
    const invalidChars = /[<>:"/\\|?*]+/g;
    let cleanStr = str.replace(invalidChars, "-");

    // Remove any leading or trailing spaces and dots
    cleanStr = cleanStr.replace(/(^\s+|\s+$)|(^\.+|\.+$)/g, "");

    // Truncate the string to 255 characters to ensure it's a valid filename length
    cleanStr = cleanStr.substring(0, 255);

    // If the string is empty at this point (or was originally just invalid chars/spaces), provide a default filename
    if (cleanStr === "") {
        cleanStr = "default-filename";
    }

    return cleanStr;
}

export const createTextStroke = (strokeSize = 1, strokeColor = "#000") => {
    let shadows = [];
    for (let i = -strokeSize; i <= strokeSize; i++) {
        for (let j = -strokeSize; j <= strokeSize; j++) {
            if (i !== 0 || j !== 0) {
                // Skip the center pixel to avoid filling the text
                if (j > 0) {
                    // Increase the bottom thickness more significantly and shift it to the right
                    let extraBottomThickness = 2 * j; // Adjust this multiplier to control bottom thickness
                    while (extraBottomThickness--) {
                        let rightShift = Math.ceil(extraBottomThickness / 2); // Adjust this for right shift
                        shadows.push(
                            `${i + rightShift}px ${j + extraBottomThickness}px 0 ${strokeColor}`
                        );
                    }
                } else {
                    shadows.push(`${i}px ${j}px 0 ${strokeColor}`);
                }
            }
        }
    }

    return `text-shadow: ${shadows.join(", ")};`;
};

export function findCircularIndex(length, number) {
    return number % length;
}

const utilsInstance = new Utils();
export default utilsInstance;
