import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoute = ({ path, component: Component, permission, render, ...rest }) => {
    let data = Cookies.get(process.env.REACT_APP_ACCESS_TOKEN_VARIABLE);

    if (!permission)
        return (
            <div>
                error
                <br />
                not enough permission
            </div>
        );

    return (
        <Route
            {...rest}
            render={(props) => {
                const isauth = !!data;
                if (!isauth) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/user/parent-signup-email",
                                state: { from: props.location },
                            }}
                        />
                    );
                }
                return Component ? <Component {...props} /> : render(props);
            }}
        />
    );
};

export default ProtectedRoute;
