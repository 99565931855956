require("dotenv").config();

const config = {
    environment: process.env.REACT_APP_ENVIRONMENT.toLowerCase(),
    backendUrl: process.env.REACT_APP_BACKEND_URL,
    backendAiUrl:
        process.env.REACT_APP_BACKEND_AI_URL ||
        "https://financy-ai-audio.vercel.app",
    frontendUrl: process.env.REACT_APP_FRONTEND_URL,
    fbPixel: process.env.REACT_APP_FACEBOOK_PIXEL_ID,
    gameUrl: process.env.REACT_APP_GAME_URL || "/game/",

    localStorageVariables: {
        email: "user-email-var-1",
        password: "user-password-var-1",
    },
};

console.log(config);

export default config;

export const isProd = () => config.environment === "production";
