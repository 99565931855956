import { useEffect, useMemo, useState } from "react";
import firebase, { storage } from "./index";
import { useAsync, useDeepCompareMemo } from "../util/hooks";

const useFirestoreQueryListener = (query) => {
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        const unsubscribe = query.onSnapshot((snapshot) => {
            const updatedDocuments = [];
            snapshot.forEach((doc) => {
                updatedDocuments.push({
                    id: doc.id,
                    data: () => doc.data(),
                });
            });
            setDocuments(updatedDocuments);
        });

        // Cleanup the listener when the component unmounts
        return () => unsubscribe();
    }, [query]);

    return documents;
};

const useFirestoreFieldListener = (collection, documentId, fields) => {
    const [fieldValues, setFieldValues] = useState({});

    const db = firebase.firestore();

    useEffect(() => {
        setFieldValues({});
    }, [documentId]);

    useEffect(() => {
        if (documentId) {
            const unsubscribe = db
                .collection(collection)
                .doc(documentId)
                .onSnapshot((doc) => {
                    const data = doc.data();
                    if (data) {
                        const newFieldValues = {};
                        fields.forEach((field) => {
                            if (field in data) {
                                newFieldValues[field] = data[field];
                            } else {
                                newFieldValues[field] = undefined;
                            }
                        });
                        setFieldValues(newFieldValues);
                    } else {
                        setFieldValues({});
                    }
                });

            // Cleanup the listener when the component unmounts
            return () => unsubscribe();
        }
    }, [collection, db, documentId, fields]);

    return fieldValues;
};

const col = "ai-jobs";
const fields = ["status"];

export const useListenForAiJobStatus = (jobId) => {
    const { status } = useFirestoreFieldListener(col, jobId, fields);

    return status;
};

export const useListenForMultipleAiJobStatus = (_jobIds) => {
    const jobIds = useDeepCompareMemo(() => _jobIds || [], [_jobIds]);

    const query = useMemo(() => {
        const db = firebase.firestore();
        let query = db.collection(col);

        if (jobIds.length > 0) {
            query = query.where(firebase.firestore.FieldPath.documentId(), "in", jobIds);
        } else {
            query = query.where(firebase.firestore.FieldPath.documentId(), "==", "null");
        }

        return query;
    }, [jobIds]);

    const data = useFirestoreQueryListener(query);

    const statuses = useMemo(() => {
        const statuses = {};
        data.forEach((doc) => {
            const { id, data } = doc;
            statuses[id] = data().status;
        });
        return statuses;
    }, [data]);

    return statuses;
};

const getStorageDownloadUrl = async (storagePath) => {
    if (!storagePath) return undefined;

    const downloadUrl = await storage
        .ref(storagePath)
        .getDownloadURL()
        .catch(() => {});

    return downloadUrl;
};

export const useGetStorageDownloadUrl = (_storagePath) => {
    const storagePath = useDeepCompareMemo(() => _storagePath, [_storagePath]);

    const downloadUrl = useAsync(getStorageDownloadUrl, storagePath);

    return downloadUrl;
};
