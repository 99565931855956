import http, { updateAuthToken } from "../httpService";

class KidService {
    async getKidDashboard() {
        try {
            const result = await http.get("/api/kids/dashboard").then((res) => res.data);
            return result;
        } catch (ex) {
            return { apierror: ex.response.data };
        }
    }

    async getKidLessonRedirect() {
        try {
            const result = await http.get("/api/kids/next-lesson").then((res) => res.data);
            return result;
        } catch (ex) {
            return { apierror: ex.response.data };
        }
    }

    async updateKidsMainOnboarding(newMainOnboarding) {
        try {
            const result = await http
                .put("/api/kids/kid-onboarding-statuses", {
                    mainOnboarding: newMainOnboarding,
                })
                .then((res) => {
                    updateAuthToken();
                    return res.data;
                });
            return result;
        } catch (ex) {
            return { apierror: ex.response.data };
        }
    }
}

const kidServiceInstance = new KidService();
export default kidServiceInstance;
