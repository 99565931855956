import { useEffect } from "react";
import tokenAuthService from "../../../services/tokenAuth/tokenAuthService";
import { useParams, useHistory } from "react-router-dom";

const ChildLoginWithToken = () => {
    const { token } = useParams();
    const history = useHistory();

    useEffect(() => {
        if (!token) return;
        tokenAuthService.logout();
        history.push("/user/child-login", {
            token,
            from: {
                pathname: "/game/",
            },
            redirectTo: "/game/",
        });
    }, [token, history]);

    return null;
};

export default ChildLoginWithToken;
