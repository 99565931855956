import React, { createContext, useState, useContext, useEffect } from "react";
import { useAsync, useLocalStorage, usePrevious } from "./hooks";
import {
    fetchKidsAndCurrentChild,
    updateCurrentChildBackend,
} from "../services/parentServices/parentServices";
import { useSelector } from "react-redux";

const ChildrenContext = createContext();

const getKidsAndCurrentChildParsedData = async () => {
    const data = await fetchKidsAndCurrentChild();
    const allKids = data?.allKids?.map((k) => {
        if (k.avatarIndex === undefined) return { ...k, avatarSprites: {} };

        return {
            ...k,
            avatarUrl: `/assets/profile-characters/char-face-${k.avatarIndex + 1}.png`,
            avatarSprites: {
                up_walk: `/assets/lesson-map/characters/${k.avatarIndex + 1}_up_walk.png`,
                down_walk: `/assets/lesson-map/characters/${k.avatarIndex + 1}_down_walk.png`,
                left_walk: `/assets/lesson-map/characters/${k.avatarIndex + 1}_left_walk.png`,
                right_walk: `/assets/lesson-map/characters/${k.avatarIndex + 1}_right_walk.png`,
            },
        };
    });

    const backendCurrentKidId = data.currentKid;

    return { allKids, backendCurrentKidId };
};

export const ChildrenProvider = ({ children }) => {
    const isParent = useSelector(({ auth }) => auth.userRole === "User");

    const { data, ...allKidsAndSelected } = useAsync(getKidsAndCurrentChildParsedData, isParent);

    const { allKids, backendCurrentKidId } = data || {};

    const [currentKidId, setCurrentKidId] = useLocalStorage("CURRENT_KID_ID", undefined);

    const prevRenderChildId = usePrevious(currentKidId);

    useEffect(() => {
        if (!currentKidId) {
            if (backendCurrentKidId) {
                setCurrentKidId(backendCurrentKidId);
            } else {
                if (allKids?.length > 0) setCurrentKidId(allKids?.[0]?._id);
            }
        }
    }, [allKids, backendCurrentKidId, currentKidId, setCurrentKidId]);

    useEffect(() => {
        if (prevRenderChildId !== currentKidId && !allKidsAndSelected.loading) {
            allKidsAndSelected.refresh();
        } else {
            if (!allKidsAndSelected.loading) {
                if (allKids?.length > 0 && currentKidId) {
                    // check if currentKidId is still valid
                    const currentKidIdx = allKids.findIndex((k) => k?._id === currentKidId);
                    if (currentKidIdx === -1) {
                        console.log("currentKidId is not valid anymore, setting to undefined");
                        setCurrentKidId(undefined);
                    }
                }
            }
        }
    }, [allKids, allKidsAndSelected, currentKidId, prevRenderChildId, setCurrentKidId]);

    const updateCurrentChild = async (childId) => {
        setCurrentKidId(childId);
        const res = await updateCurrentChildBackend(childId);
        allKidsAndSelected.refresh();
        return res;
    };

    const currentKidIdx = (allKids || []).findIndex((k) => k?._id === currentKidId);

    const currentKid = (allKids || [])[currentKidIdx];

    useEffect(() => {
        allKidsAndSelected.refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isParent]);

    useEffect(() => {
        if (currentKid?.avatarSprites) {
            // preload avatar sprites
            Object.values(currentKid.avatarSprites).forEach((spriteUrl) => {
                const img = new Image();
                img.src = spriteUrl;
            });
        }
    }, [currentKid]);

    return (
        <ChildrenContext.Provider
            value={{
                allKids,
                currentKid,
                currentKidIdx,
                currentKidId,
                updateCurrentChild,
                ...allKidsAndSelected,
            }}
        >
            {children}
        </ChildrenContext.Provider>
    );
};

export const useChildren = () => {
    const context = useContext(ChildrenContext);
    if (!context) {
        throw new Error("useChildren must be used within an ChildrenProvider");
    }
    return context;
};
