import Cookies from "js-cookie";
import http from "../httpService";
import utils from "../../util/utils";
import { setAmplitudeUserId } from "../../util/amplitude";
class TokenAuthService {
    //check if user is authenticated
    isAuthenticated() {
        let data = Cookies.get(process.env.REACT_APP_ACCESS_TOKEN_VARIABLE);
        if (!data) return false;
        return true;
    }

    getAccessToken = () => Cookies.get(process.env.REACT_APP_ACCESS_TOKEN_VARIABLE);
    getRefreshToken = () => Cookies.get(process.env.REACT_APP_REFRESH_TOKEN_VARIABLE);

    //login with username and password
    async login(model) {
        try {
            const result = await http.post("/api/auth/login", {
                email: model.email,
                password: model.password,
            });
            const expdate = utils.getExpiryDate();
            Cookies.set(
                process.env.REACT_APP_ACCESS_TOKEN_VARIABLE,
                result.headers["x-auth-token"],
                {
                    expires: expdate,
                }
            );

            Cookies.set(
                process.env.REACT_APP_REFRESH_TOKEN_VARIABLE,
                result.headers["x-auth-refresh-token"],
                {
                    expires: expdate,
                }
            );

            return {};
        } catch (ex) {
            return { apierror: ex.response.data };
        }
    }

    async childLogin(model) {
        try {
            const result = await http.post("/api/kids/kid-login", {
                onboardingCode: model.loginCode,
            });

            const expdate = utils.getExpiryDate();
            if (result.headers) {
                Cookies.set(
                    process.env.REACT_APP_ACCESS_TOKEN_VARIABLE,
                    result.headers["x-auth-token"],
                    { expires: expdate }
                );
                Cookies.set(
                    process.env.REACT_APP_REFRESH_TOKEN_VARIABLE,
                    result.headers["x-auth-refresh-token"],
                    { expires: expdate }
                );
            }
            return {
                parentEmail: result.data.parent.email,
            };
        } catch (ex) {
            console.log("----ex----", ex);
            return { apierror: ex.response.data };
        }
    }

    async refreshChildToken() {
        try {
            let refresh_token = Cookies.get(process.env.REACT_APP_REFRESH_TOKEN_VARIABLE);
            const result = await http.post("/api/auth/refresh-token-kid", {
                refresh_token,
            });
            const expdate = utils.getExpiryDate();
            if (result.headers) {
                Cookies.set(
                    process.env.REACT_APP_ACCESS_TOKEN_VARIABLE,
                    result.headers["x-auth-token"],
                    { expires: expdate }
                );
                Cookies.set(
                    process.env.REACT_APP_REFRESH_TOKEN_VARIABLE,
                    result.headers["x-auth-refresh-token"],
                    { expires: expdate }
                );
            }
        } catch (ex) {
            console.log("----ex----", ex);
            return { apierror: ex.response.data };
        }
    }

    async signupEmailSave(email) {
        try {
            const result = await http.post("/api/users/signup-flow-email", {
                email,
            });

            return result.data.status;
        } catch (ex) {
            return { apierror: ex.response.data };
        }
    }

    async signup(model) {
        try {
            let postObj = {
                firstname: model.firstname,
                lastname: model.lastname,
                email: model.email,
                password: model.password,
            };

            if (model.partner) {
                postObj["partner"] = model.partner;
            }

            console.log(postObj);

            const result = await http.post("/api/users", postObj);
            console.log(result);
            const expdate = utils.getExpiryDate();
            Cookies.set(
                process.env.REACT_APP_ACCESS_TOKEN_VARIABLE,
                result.headers["x-auth-token"],
                {
                    expires: expdate,
                }
            );

            Cookies.set(
                process.env.REACT_APP_REFRESH_TOKEN_VARIABLE,
                result.headers["x-auth-refresh-token"],
                {
                    expires: expdate,
                }
            );

            return {};
        } catch (ex) {
            console.log("----ex----", ex);
            return { apierror: ex.response.data };
        }
    }

    async getPartnerIfValidSlug(slug) {
        try {
            const result = await http.get("/api/partners?slug=" + slug);
            return result.data;
        } catch (ex) {
            console.log("----ex----", ex);
            return { apierror: ex.response.data };
        }
    }

    async resetPasswordRequest(model) {
        try {
            const result = await http.put("/api/auth/reset-password-request", {
                email: model.email,
            });
            console.log(result);
            // console.log("{---=--result.headers----",result.headers["x-auth-token"]);
            // const expdate = utils.getExpiryDate();
            // Cookies.set(process.env.REACT_APP_ACCESS_TOKEN_VARIABLE, result.headers["x-auth-token"], {
            //   expires: expdate,
            // });

            // Cookies.set(process.env.REACT_APP_REFRESH_TOKEN_VARIABLE, result.headers["x-auth-refresh-token"], {
            //   expires: expdate,
            // });

            return {};
        } catch (ex) {
            console.log("----ex----", ex);
            return { apierror: ex.response.data };
        }
    }

    async resetPassword(model) {
        try {
            const result = await http.put(`/api/users/reset-password?tkn=${model.tkn}`, {
                password: model.password,
            });
            console.log(result);
            // console.log("{---=--result.headers----",result.headers["x-auth-token"]);
            // const expdate = utils.getExpiryDate();
            // Cookies.set(process.env.REACT_APP_ACCESS_TOKEN_VARIABLE, result.headers["x-auth-token"], {
            //   expires: expdate,
            // });

            // Cookies.set(process.env.REACT_APP_REFRESH_TOKEN_VARIABLE, result.headers["x-auth-refresh-token"], {
            //   expires: expdate,
            // });

            return {};
        } catch (ex) {
            console.log("----ex----", ex);
            return { apierror: ex.response.data };
        }
    }

    //logout - clean all storages n cookies
    logout() {
        localStorage.clear();
        sessionStorage.clear();
        Cookies.remove(process.env.REACT_APP_ACCESS_TOKEN_VARIABLE);
        Cookies.remove(process.env.REACT_APP_REFRESH_TOKEN_VARIABLE);
    }

    async checkLoginToken(loginToken) {
        const result = await http.post("/api/auth/check-login-token", {
            loginToken,
        });
        console.log(result);
        setAmplitudeUserId(result.data.email);

        const expdate = utils.getExpiryDate();

        Cookies.set(process.env.REACT_APP_ACCESS_TOKEN_VARIABLE, result.headers["x-auth-token"], {
            expires: expdate,
        });

        Cookies.set(
            process.env.REACT_APP_REFRESH_TOKEN_VARIABLE,
            result.headers["x-auth-refresh-token"],
            {
                expires: expdate,
            }
        );
        return result.json();
    }
}

const tokenAuthServiceInstance = new TokenAuthService();
export default tokenAuthServiceInstance;
