import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

class SessionService {
    getLoggedUserData() {
        let token = Cookies.get(process.env.REACT_APP_ACCESS_TOKEN_VARIABLE);
        let current;
        try {
            current = jwt_decode(token);
            return current;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
}

const sessionServiceInstance = new SessionService();
export default sessionServiceInstance;
