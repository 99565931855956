import { useEffect } from "react";
import tokenAuthService from "../../../services/tokenAuth/tokenAuthService";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useUpdateCurrentUserRole } from "../../../util/hooks";
import { sendAmplitudeEvent } from "../../../util/amplitude";

const ParentLoginWithToken = () => {
    const updateCurrentUserRole = useUpdateCurrentUserRole();
    const { token } = useParams();
    const history = useHistory();
    const { search } = useLocation();

    useEffect(() => {
        if (!token) return;
        const redirectPath = new URLSearchParams(search).get("to") || "/";
        const isReturningUser = new URLSearchParams(search).get("ru") === "true";

        tokenAuthService.logout();

        if (isReturningUser) {
            localStorage.setItem("IS_RETURNING_USER", JSON.stringify(true));
        }

        tokenAuthService.checkLoginToken(token).finally(() => {
            sendAmplitudeEvent("v4 - 2c - Parent Login With Token");
            history.replace(redirectPath);
            updateCurrentUserRole();
        });
    }, [token, history, search, updateCurrentUserRole]);

    return <></>;
};

export default ParentLoginWithToken;
