import * as React from "react";
import Router from "./components/Router";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import utils from "./util/utils";
import "react-toastify/dist/ReactToastify.css";
import { setupDataOnLoad } from "./store/actions/authAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    postUserSessionAnalytic,
    postUserAcquisition,
} from "./services/analyticServices/analyticServices";

class App extends React.Component {
    componentDidMount() {
        this.props.setupDataOnLoad();
        console.log(`Financy - ${process.env.REACT_APP_ENVIRONMENT}`);

        // Get the URL search params
        const searchParams = new URLSearchParams(this.props.location.search);
        const acqParam = searchParams.get("acq"); // Replace 'myParam' with the name of your parameter
        if (acqParam) {
            console.log(`Search Param 'acqParam': ${acqParam}`);
            localStorage.setItem("acq", acqParam);
            postUserAcquisition(acqParam);

            searchParams.delete("acq");
            // Create a new URL with the updated search parameters
            const newSearchString = searchParams.toString();
            const newPath = `${this.props.location.pathname}?${newSearchString}`;
            // Navigate to the new URL
            this.props.history.push(newPath);
        }

    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    {process.env.REACT_APP_FACEBOOK_PIXEL_ID && (
                        <script type="text/javascript">
                            {`
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}
                            (window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', ${process.env.REACT_APP_FACEBOOK_PIXEL_ID});
                            fbq('track', 'PageView');
                        `}
                        </script>
                    )}
                    {process.env.REACT_APP_FACEBOOK_PIXEL_ID && (
                        <noscript>
                            {`
                            <img
                                height="1"
                                width="1"
                                style="display:none"
                                src="https://www.facebook.com/tr?id={${process.env.REACT_APP_FACEBOOK_PIXEL_ID}}&ev=PageView&noscript=1"
                            />
                        `}
                        </noscript>
                    )}

                    {process.env.REACT_APP_HOTJAR_ID && (
                        <script type="text/javascript">
                            {`
                            (function(h, o, t, j, a, r) {
                                h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments) };
                                h._hjSettings = { hjid: ${process.env.REACT_APP_HOTJAR_ID}, hjsv:6 };
                                a = o.getElementsByTagName('head')[0];
                                r = o.createElement('script'); r.async = 1;
                                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                                a.appendChild(r);
                            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
                        `}
                        </script>
                    )}
                </Helmet>

                <ToastContainer />
                <div>
                    <Router />
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    let stateBuilder = {};
    if (state.auth.allCoins) stateBuilder.allCoins = state.auth.allCoins;
    if (state.auth.emailSignupSuccess) stateBuilder.emailSignupSuccess = true;

    return stateBuilder;
};

const mapDispatchToProps = (dispatch) => {
    return {
        // Returns a promise
        setupDataOnLoad: () => dispatch(setupDataOnLoad()),
    };
};

// export default connect(mapStateToProps, mapDispatchToProps)(App);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
