import axios from "../httpService";

export const postUserSessionAnalytic = async () => {
    const { data: result } = await axios.post("/api/analytics/user-session");
    console.log(result);
    return result;
};

export const postUserAcquisition = async (acqId) => {
    const { data: result } = await axios.post("/api/analytics/user-acquisition", { acqId });
    console.log(result);
    return result;
};
