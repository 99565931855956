import Cookies from "js-cookie";
import utils from "../../util/utils";
import axios from "../httpService";
import { sendAmplitudeEvent } from "../../util/amplitude";

export const parentDashboard = async () => {
    const { data: result } = await axios.get("/api/parents/dashboard");
    return result;
};

export const invalidChildAge = async (age) => {
    const { data: result } = await axios.post("/api/parents/invalid-child-age", { age });
    return result;
};

export const skipAddingKid = async () => {
    const result = await axios.post("/api/parents/skip-now");
    await Cookies.set(process.env.REACT_APP_ACCESS_TOKEN_VARIABLE, result.headers["x-auth-token"], {
        expires: utils.getExpiryDate(),
    });

    await Cookies.set(
        process.env.REACT_APP_REFRESH_TOKEN_VARIABLE,
        result.headers["x-auth-refresh-token"],
        {
            expires: utils.getExpiryDate(),
        }
    );

    return result;
};

export const getKidNextLesson = async (kidId) => {
    const { data } = await axios.get("/api/parents/kid-next-lesson/" + kidId);
    return data;
};

export const addKid = async (data) => {
    const { data: result } = await axios.post("/api/parents/add-a-kid", data);
    return result;
};

export const fetchKids = async () => {
    const { data: result } = await axios.get("/api/parents/my-kids");
    return result;
};

export const fetchKidsAndCurrentChild = async () => {
    const { data: result } = await axios.get("/api/parents/all-and-selected-kids");
    return result;
};

export const updateCurrentChildBackend = async (childId) => {
    const { data: result } = await axios.post("/api/parents/update-current-child", { childId });
    return result;
};

export const saveParentOnboardingPrefs = async (data) => {
    const { data: result } = await axios.post("/api/parents/onboarding-prefs", data);
    return result;
};

export const saveChildsQuestProgress = async (data) => {
    const { data: result } = await axios.post("/api/parents/kid-completed-lesson", data);
    return result;
};

export const getParentReminders = async () => {
    const { data: result } = await axios.get("/api/notifications/get-reminders");
    return result;
};

export const createNewParentReminder = async (data) => {
    const result = await axios.post("/api/notifications/new-parent-reminder", data);
    return result;
};

export const deleteParentReminder = async (reminderId) => {
    const result = await axios.post("/api/notifications/delete-reminder", {
        reminderId,
    });
    return result;
};

export const submitKidInitialPreferences = async ({ selectedThemes, avatarIndex, childId }) => {
    sendAmplitudeEvent("v4 - 5 - Parent added kid's theme, avatar");
    return await axios.post("/api/parents/submit-kid-initial-preferences", {
        selectedThemes,
        childId,
        avatarIndex,
    });
};

export const saveTopicSuggestion = async (data) => {
    const { data: result } = await axios.post("/api/parents/new-topic-suggestion", data);
    return result;
};

export const startingChildLesson = async (currentKidId) => {
    const { data: result } = await axios.post("/api/parents/child-starting-lesson", {
        currentKidId,
    });
    return result;
};
