import Cookies from "js-cookie";
import * as actions from "../actionTypes/authActionTypes";

const initialState = {
    singleCoinData: {},
    apiError: {},
    userLoggedIn: !!Cookies.get(process.env.REACT_APP_ACCESS_TOKEN_VARIABLE),
    userIsChild: false,
    userRole: null,
    userEmail: null,
    userFirstName: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_API_ERROR:
            state = action.payload;
            break;
        case actions.SETUP_ON_LOAD:
            return Object.assign({}, state, {
                userLoggedIn: !!Cookies.get(process.env.REACT_APP_ACCESS_TOKEN_VARIABLE),
            });

        case actions.SHOW_AN_OVERLAY:
            return Object.assign({}, state, {
                overlay: action.overlay,
            });

        case actions.SIGNUP_WITH_EMAIL:
            return Object.assign({}, state, {
                emailSignupSuccess: true,
                userLoggedIn: true,
            });

        case actions.SIGNIN_WITH_PASSWORD:
            return Object.assign({}, state, {
                userLoggedIn: true,
            });
        case actions.SIGNOUT_USER:
            return Object.assign({}, state, {
                userLoggedIn: false,
            });
        case actions.SIGNIN_USER_SUCCESS:
            return Object.assign({}, state, {
                userLoggedIn: true,
            });
        case actions.AUTH_SET_USER_ROLE:
            return { ...state, userIsChild: action.payload === "Kid", userRole: action.payload };
        case actions.AUTH_SET_USER_EMAIL:
            return { ...state, userEmail: action.payload };
        case actions.AUTH_SET_USER_FIRST_NAME:
            return { ...state, userFirstName: action.payload };
        default:
            state = {};
    }
    return state;
};

export default authReducer;
