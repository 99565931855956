import { combineReducers } from "redux";
import authReducer from "./authReducer";
import { connectRouter } from "connected-react-router";

const reducer = (history) =>
    combineReducers({
        auth: authReducer,
        router: connectRouter(history),
    });

export default reducer;
