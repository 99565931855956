export const SET_API_ERROR = "SET_API_ERROR";
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
// Action Types
export const SETUP_ON_LOAD = "SETUP_ON_LOAD";
export const SHOW_AN_OVERLAY = "SHOW_AN_OVERLAY";
export const SIGNUP_WITH_EMAIL = "SIGNUP_WITH_EMAIL";
export const SIGNIN_WITH_PASSWORD = "SIGNIN_WITH_PASSWORD";
export const SIGNIN_WITH_PASSWORD_FAIL = "SIGNIN_WITH_PASSWORD_FAIL";

export const AUTH_SET_USER_ROLE = "AUTH_SET_USER_ROLE";
export const AUTH_SET_USER_EMAIL = "AUTH_SET_USER_EMAIL";
export const AUTH_SET_USER_FIRST_NAME = "AUTH_SET_USER_FIRST_NAME";
