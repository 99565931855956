import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback,
    useRef,
    forwardRef,
    useMemo,
} from "react";
import { useEventListener } from "./hooks";

const AudioSettingsContext = createContext();
const AudioStorageContext = createContext();

const AudioStorageProvider = ({ children }) => {
    const [audioElementsIds, setAudioElementsIds] = useState({});
    const [audioElementsLoaded, setAudioElementsLoaded] = useState({});
    const [audioElementsCreated, setAudioElementsCreated] = useState({});
    const audioContainerRef = useRef(null);

    const addAudioElement = (src, audioId) => {
        setAudioElementsIds((prevAudioElementsIds) => {
            if (prevAudioElementsIds[src]) {
                return prevAudioElementsIds;
            }

            return {
                ...prevAudioElementsIds,
                [src]: audioId,
            };
        });
        setAudioElementsLoaded((prevAudioElementsLoaded) => {
            if (prevAudioElementsLoaded[src]) {
                return prevAudioElementsLoaded;
            }

            return {
                ...prevAudioElementsLoaded,
                [src]: false,
            };
        });
    };

    const createAudioElement = useCallback((src) => {
        let audioId = null;
        let alreadyCreated = false;

        // iterate on all elements with class AUDIO-CONTEXT-AUDIO-ELEMENT
        // if src matches, set audioId to the id of the element
        // if src doesn't match, increment audioElementsLength
        const audioElements = document.getElementsByClassName("AUDIO-CONTEXT-AUDIO-ELEMENT");
        for (let i = 0; i < audioElements.length; i++) {
            const audioElement = audioElements[i];
            if (audioElement.src?.endsWith(src)) {
                audioId = audioElement.id;
                alreadyCreated = true;
                break;
            }
        }

        let audioElementsLength = audioElements.length;

        if (!alreadyCreated) {
            audioId = `${Math.random()}-${audioElementsLength}`;
            addAudioElement(src, audioId);
        }

        return { audioId, alreadyCreated };
    }, []);

    useEffect(() => {
        if (!audioContainerRef.current) {
            const _audioContainerRef = document.getElementById("preloaded-audio-container");
            audioContainerRef.current = _audioContainerRef;
        }
    });

    useEffect(() => {
        // iterate through audioElementsIds
        // if audioElementId is not in audioElementsCreated
        // create audio element and add it to audioElementsCreated
        if (!audioContainerRef.current) return;

        Object.keys(audioElementsIds).forEach((src) => {
            const audioId = audioElementsIds[src];

            if (!audioElementsCreated[src]) {
                const audioElement = new Audio(src);
                audioElement.preload = "auto";
                audioElement.id = audioId;
                audioElement.classList.add("AUDIO-CONTEXT-AUDIO-ELEMENT");

                audioElement.load();
                audioElement.addEventListener("canplaythrough", () => {
                    setAudioElementsLoaded((prevAudioElementsLoaded) => {
                        return {
                            ...prevAudioElementsLoaded,
                            [src]: true,
                        };
                    });
                });

                audioContainerRef.current.appendChild(audioElement);

                setAudioElementsCreated((prevAudioElementsCreated) => {
                    return {
                        ...prevAudioElementsCreated,
                        [src]: true,
                    };
                });
            }
        });
    }, [audioElementsCreated, audioElementsIds]);

    return (
        <>
            <AudioStorageContext.Provider
                value={{
                    createAudioElement,
                    audioElementsLoaded,
                    audioContainerRef,
                    audioElementsCreated,
                }}
            >
                {children}
            </AudioStorageContext.Provider>
        </>
    );
};

const AudioSettingsProvider = ({ children }) => {
    const [isMuted, setMuted] = useState(false);
    const [isSoftMuted, setSoftMuted] = useState(false);

    useEventListener(window.AUDIO_UPDATE_EVENT, () => {
        setMuted(window.check_is_audio_muted());
    });

    const handleDisableAudio = () => {
        window.turn_audio_off();
    };

    const handleEnableAudio = () => {
        window.turn_audio_on();
    };

    const handleSoftMute = () => {
        setSoftMuted(true);
    };

    const handleSoftUnmute = () => {
        setSoftMuted(false);
    };

    const isMutedInternal = useMemo(() => isMuted || isSoftMuted, [isMuted, isSoftMuted]);

    useEffect(() => {
        setMuted(window.check_is_audio_muted());
    }, []);

    return (
        <AudioSettingsContext.Provider
            value={{
                isMuted,
                isMutedInternal,
                handleSoftMute,
                handleSoftUnmute,
                handleDisableAudio,
                handleEnableAudio,
            }}
        >
            {children}
        </AudioSettingsContext.Provider>
    );
};

export const useAudio = () => {
    const context = useContext(AudioSettingsContext);
    if (!context) {
        throw new Error("useAudio must be used within an AudioProvider");
    }
    return context.isMuted;
};

export const useAudioInternal = () => {
    const context = useContext(AudioSettingsContext);
    if (!context) {
        throw new Error("useAudio must be used within an AudioProvider");
    }
    return context.isMutedInternal;
};

export const useAudioToggle = () => {
    const context = useContext(AudioSettingsContext);
    if (!context) {
        throw new Error("useAudioToggle must be used within an AudioProvider");
    }

    const { handleDisableAudio, handleEnableAudio, handleSoftMute, handleSoftUnmute } = context;

    return { handleDisableAudio, handleEnableAudio, handleSoftMute, handleSoftUnmute };
};

const useAudioStorage = () => {
    const context = useContext(AudioStorageContext);
    if (!context) {
        throw new Error("useAudioStorage must be used within an AudioProvider");
    }
    return context;
};

export const AudioProvider = ({ children }) => {
    return (
        <AudioSettingsProvider>
            <AudioStorageProvider>{children}</AudioStorageProvider>
        </AudioSettingsProvider>
    );
};

export const usePreloadAudio = () => {
    const { createAudioElement, audioElementsLoaded } = useAudioStorage();

    const preloadAudio = useCallback(
        async (src) => {
            const { audioId, alreadyCreated } = createAudioElement(src);

            if (alreadyCreated && audioElementsLoaded[src]) {
                return audioId;
            }

            return new Promise((resolve) => {
                // check if document.getElementById(audioId) is null
                // if it is, then set timeout to 500ms and try again
                // until it's not null

                const audioElement = document.getElementById(audioId);

                if (!audioElement) {
                    setTimeout(() => {
                        preloadAudio(src).then(resolve);
                    }, 500);
                } else {
                    audioElement.onloadeddata = () => {
                        resolve(audioElement);
                    };
                    document.getElementById(audioId).onerror = () => {
                        resolve(null); // resolve even if there's an error
                    };
                }
            });
        },
        [createAudioElement, audioElementsLoaded]
    );

    return preloadAudio;
};

const useGetLoadedAudioId = (src) => {
    const { createAudioElement, audioElementsCreated, audioElementsLoaded } = useAudioStorage();
    const [audioIdInternal, setAudioIdInternal] = useState(null);
    const [audioId, setAudioId] = useState(audioIdInternal);
    const isAudioLoaded = useIsAudioLoaded(src);

    useEffect(() => {
        const { audioId: newAudioId } = createAudioElement(src);
        setAudioIdInternal(newAudioId);
    }, [src, createAudioElement, audioElementsLoaded]);

    useEffect(() => {
        if (audioElementsCreated[src]) {
            setAudioId(audioIdInternal);
        }
    }, [audioElementsCreated, src, audioIdInternal]);

    return { isAudioLoaded, audioElementId: audioId };
};

export const useIsAudioLoaded = (src) => {
    const { audioElementsLoaded } = useAudioStorage();
    const [isAudioLoaded, setIsAudioLoaded] = useState(false);

    useEffect(() => {
        if (src && audioElementsLoaded[src]) {
            setIsAudioLoaded(true);
        }
        if (!src) {
            setIsAudioLoaded(false);
        }
    }, [audioElementsLoaded, src]);

    return isAudioLoaded;
};

export const CachedAudioComponent = forwardRef(
    (
        {
            src,
            onPlay,
            onCanPlay,
            onLoadStart,
            onEnded,
            onLoadedData,
            grabbedClassNameRef = {},
            onRefSet = () => {},
        },
        _audioElementRef
    ) => {
        const { audioElementId, isAudioLoaded } = useGetLoadedAudioId(src);
        const { audioContainerRef: globalAudioContainerRef } = useAudioStorage();

        const audioElementRef = useRef(null);
        const containerRef = useRef(null);

        useEffect(() => {
            let _audioElement = null;
            const grabbedClassName = `grabbed-${Math.random()}`;
            grabbedClassNameRef.current = grabbedClassName;

            if (audioElementId) {
                _audioElement = document.getElementById(audioElementId);
                if (_audioElement) {
                    audioElementRef.current = _audioElement;
                    _audioElement.classList.remove("playing");
                    _audioElement.pause();
                    _audioElement.currentTime = 0;
                    _audioElement.classList.add(grabbedClassName);
                }
            }

            return () => {
                if (_audioElement) {
                    _audioElement.classList.remove(grabbedClassName);
                    _audioElement.classList.remove("playing");
                    _audioElement.pause();
                    _audioElement.currentTime = 0;
                }
            };
        }, [audioElementId, globalAudioContainerRef, isAudioLoaded]);

        useEffect(() => {
            if (_audioElementRef) {
                if (!_audioElementRef.current && audioElementRef.current) {
                    onRefSet();
                }
                _audioElementRef.current = audioElementRef.current;
            }
        });

        useEffect(() => {
            // Get the audio element from the DOM
            const _audioElement = audioElementRef.current;

            if (_audioElement) {
                // Append the audio element to the React component

                // Add event listeners
                _audioElement.addEventListener("play", onPlay);
                _audioElement.addEventListener("canplay", onCanPlay);
                _audioElement.addEventListener("loadstart", onLoadStart);
                _audioElement.addEventListener("ended", onEnded);
                _audioElement.addEventListener("loadeddata", onLoadedData);
            }

            // Cleanup: Remove the audio element and event listeners when the component unmounts
            return () => {
                if (_audioElement) {
                    _audioElement.removeEventListener("play", onPlay);
                    _audioElement.removeEventListener("canplay", onCanPlay);
                    _audioElement.removeEventListener("loadstart", onLoadStart);
                    _audioElement.removeEventListener("ended", onEnded);
                    _audioElement.removeEventListener("loadeddata", onLoadedData);
                }
            };
        }, [
            audioElementId,
            onPlay,
            onCanPlay,
            onLoadStart,
            onEnded,
            onLoadedData,
            audioElementRef,
        ]);

        return <div ref={containerRef} />;
    }
);
