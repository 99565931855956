import asyncComponent from "../../util/asyncComponent";
import { ADMIN, USER, KID } from "../../constants/LookupConst";
// import { PlayCircleOutlined } from "@ant-design/icons";

// right now /user/login and /user/signup use the same scene, but the scene component is remounting when navigating between these two routes
// because each route has a different instance of the scene. It would be nice if we could change this in order to get a more single page app feel
// essentially just load one async component for both /user/login & /user/signup

export const anonymousRoutes = [
    {
        path: "/user",
        component: asyncComponent(() => import("../Layout/AnonymousUserLayout")),
        isLayout: true,
        showInMenu: false,
    },
    // Functional routes
    // {
    //     path: "/user/landing",
    //     component: asyncComponent(() => import("../../scenes/Anonymous/Landing")),
    //     showInMenu: false,
    // },

    {
        path: "/user/parent-signup-email",
        component: asyncComponent(() => import("../../scenes/Auth/ParentSignupEmail")),
        showInMenu: false,
    },
    {
        path: "/user/parent-signup-details",
        component: asyncComponent(() => import("../../scenes/Auth/ParentSignup")),
        showInMenu: false,
    },
    {
        path: "/user/parent-login",
        component: asyncComponent(() => import("../../scenes/Auth/ParentLogin")),
        showInMenu: false,
    },
    {
        path: "/user/child-login",
        component: asyncComponent(() => import("../../scenes/Auth/ChildLogin")),
        showInMenu: false,
    },

    {
        path: "/user/reset-password",
        component: asyncComponent(() => import("../../scenes/Auth")),
        showInMenu: false,
    },
    {
        path: "/user/reset-password-success",
        component: asyncComponent(() => import("../../scenes/Auth")),
        showInMenu: false,
    },
    {
        path: "/user/trouble-signing-in",
        component: asyncComponent(() => import("../../scenes/Auth")),
        showInMenu: false,
    },
    {
        path: "/user/trouble-signing-in-success",
        component: asyncComponent(() => import("../../scenes/Auth")),
        showInMenu: false,
    },

    // Partner stuff (not in use)
    {
        path: "/user/partner/:partnerid",
        component: asyncComponent(() => import("../../scenes/Anonymous/Partner")),
        showInMenu: false,
    },

    // Visual only routes (non-functional )
    {
        // TODO: Add this in the next version?
        path: "/user/avatar-selection",
        component: asyncComponent(() => import("../../scenes/Anonymous/AvatarSelection")),
        showInMenu: false,
    },
    // Used a different version of this already in logged in route: /app/child-dashboard
    {
        path: "/user/child",
        component: asyncComponent(() => import("../../scenes/Anonymous/ChildMain")),
        showInMenu: false,
    },
    // Parent routes (next 3), we'll use these for
    {
        path: "/user/parent",
        component: asyncComponent(() => import("../../scenes/Anonymous/ParentMain")),
        showInMenu: false,
    },
    {
        path: "/user/parent/profile",
        component: asyncComponent(() => import("../../scenes/Anonymous/ParentMain")),
        showInMenu: false,
    },
    {
        path: "/user/parent/child/:id",
        component: asyncComponent(() => import("../../scenes/Anonymous/ParentMain")),
        showInMenu: false,
    },
    // We will use this later on for blogs
    {
        path: "/user/parent/resources",
        component: asyncComponent(() => import("../../scenes/Anonymous/ParentMain")),
        showInMenu: false,
    },
    {
        path: "/user/parent/resources/:id",
        component: asyncComponent(() => import("../../scenes/Anonymous/ParentMain")),
        showInMenu: false,
    },
];

export const authenticatedRoutes = [
    {
        path: "/",
        exact: true,
        component: asyncComponent(() => import("../Layout/AuthenticatedUserLayout")),
        isLayout: true,
        showInMenu: false,
    },

    // USER / PARENT ROUTES
    {
        path: "/app/parent-onboarding",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/ParentOnboarding")),
    },
    {
        path: "/app/child-dashboard",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Child/Dashboard")),
    },
    {
        path: "/app/child-dashboard/onboarding-form",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Child/Dashboard")),
    },
    {
        path: "/app/profiles",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/Profiles")),
    },
    {
        path: "/app/curriculum/:childIdentifier",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/ChildCurriculum")),
    },
    {
        path: "/app/add-to-curriculum/:childIdentifier",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/AddToCurriculum")),
    },
    {
        path: "/app/quest-redirect",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/ChildLessonRedirect")),
    },

    {
        path: "/app/quest-map",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/ChildLessonMap")),
    },

    {
        path: "/app/quest/:slug",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/ChildLesson")),
    },

    {
        path: "/app/dashboard",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/Dashboard")),
    },
    {
        path: "/app/parent-login-child",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/LoginChild")),
    },
    {
        path: "/app/add-a-kid",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/AddAKid")),
    },
    {
        path: "/app/dashboard/notifications",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/Dashboard")),
    },
    {
        path: "/app/modules",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/Modules")),
    },
    {
        path: "/app/ai-generator",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/AIGenerator")),
    },
    {
        path: "/app/help",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/Help")),
    },

    {
        path: "/app/help/reminders",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/Reminders")),
    },

    {
        path: "/app/help/reminders/add",
        role: [USER],
        component: asyncComponent(() => import("../../scenes/Parent/Reminders/AddReminders")),
    },

    // KID ROUTES
    {
        path: "/app/child-onboarding",
        role: [KID],
        component: asyncComponent(() => import("../../scenes/Child/ChildOnboarding")),
    },
    // {
    //     path: "/app/child-dashboard",
    //     role: [KID],
    //     component: asyncComponent(() => import("../../scenes/Child/Dashboard")),
    // },
    {
        path: "/app/child-island-map",
        role: [KID],
        component: asyncComponent(() => import("../../scenes/Child/IslandMap")),
    },
    {
        path: "/app/lesson-redirect",
        role: [KID],
        component: asyncComponent(() => import("../../scenes/Child/LessonRedirect")),
    },
    // {
    //     path: "/app/lesson/:slug/:id",
    //     role: [KID],
    //     component: asyncComponent(() => import("../../scenes/Child/Lesson")),
    // },

    // ADMIN ROUTES
    {
        path: "/admin/dashboard",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/Dashboard")),
    },
    {
        path: "/admin/manage-modules",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/ManageModules")),
    },
    {
        path: "/admin/manage-ai-curriculums",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/ManageAiCurriculums")),
    },
    {
        path: "/admin/manage-ai-curriculums/:slug",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() =>
            import("../../scenes/Admin/ManageAiCurriculums/ViewModule")
        ),
    },
    {
        path: "/admin/manage-ai-curriculums/:slug/edit",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/ManageModule")),
    },
    {
        path: "/admin/manage-ai-curriculums/:slug/regenerate",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() =>
            import("../../scenes/Admin/ManageAiCurriculums/Regenerate")
        ),
    },
    {
        path: "/admin/module/:slug",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/ManageModule")),
    },
    {
        path: "/admin/users",
        role: [ADMIN],
        showInMenu: false,
        component: asyncComponent(() => import("../../scenes/Admin/AllUsers")),
    },
];

export const routers = [...anonymousRoutes, ...authenticatedRoutes];
